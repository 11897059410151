import React, { useCallback, useState } from "react"
import * as S from "./styled"
import { animated, useTransition } from "react-spring"

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  const [hover, setHover] = useState(false)
  const transitions = useTransition(hover, {
    from: { transform: "translateX(-100%)" },
    enter: { transform: "translateX(0)" },
    leave: { transform: "translateX(100%)" },
  })

  const onMouseEnter = useCallback(() => setHover(true), [])
  const onMouseLeave = useCallback(() => setHover(false), [])
  return (
    <S.ButtonComponent
      onMouseEnter={() => !props.minimal && onMouseEnter()}
      onMouseLeave={() => !props.minimal && onMouseLeave()}
      {...props}
    >
      {!props.minimal && transitions(
        (style, item) => item && <animated.div id={"hover"} style={style} />
      )}
      <S.ChildrenContainer>{children}</S.ChildrenContainer>
    </S.ButtonComponent>
  )
}

export const IconButton = S.IconButton
