import React, { useCallback, useLayoutEffect, useState } from "react"
import ScrollLock from "react-scrolllock"
import * as S from "./styled"

// @ts-ignore
import { ReactComponent as Letters } from "../../vector/letters.svg"
// @ts-ignore
import { ReactComponent as Logo } from "../../vector/logo.svg"
import { useSpring } from "react-spring"
import { LoadingContext } from "../../context/loading"
import { useMedia } from "use-media"
import { size } from "../Theme/devices"

const ActualPreloader: React.FC<{
  showLetters: boolean
  canGoAway: boolean
  onRest: () => any
}> = props => {
  const [wentAway, setWentAway] = useState<boolean>(false)
  const isTablet = useMedia({ minWidth: size.tablet })
  const lettersSpring = useSpring({
    pause: !props.showLetters,
    delay: 500,
    from: {
      width: 0,
    },
    to: {
      width: isTablet ? 444 : 222,
    },
  })
  const goAwaySpring = useSpring({
    pause: !props.canGoAway,
    delay: 3000,
    from: {
      transform: "translateX(0%)",
    },
    to: {
      transform: "translateX(100%)",
    },
    onRest: () => {
      setWentAway(true)
      props.onRest()
    },
  })
  return (
    <S.Container visible={!wentAway} style={goAwaySpring}>
      <ScrollLock isActive={!wentAway} accountForScrollbars />
      <S.LogoContainer>
        <div />
        <Logo />
        <S.LettersContainer style={lettersSpring}>
          <Letters />
        </S.LettersContainer>
      </S.LogoContainer>
    </S.Container>
  )
}

export const Preloader: React.FC = props => {
  const [hydrated, setHydrated] = useState<boolean>(false)
  const [documentReady, setDocumentReady] = useState<boolean>(false)
  const [fontsLoaded, setFontsLoaded] = useState<boolean>(false)
  const onDocReady = useCallback(() => {
    setDocumentReady(true)
    // @ts-ignore
    if (typeof document.fonts === "undefined") {
      setFontsLoaded(true)
    } else {
      // @ts-ignore
      document.fonts.ready
        .then(() => setFontsLoaded(true))
        .catch(() => setFontsLoaded(true))
    }
  }, [])
  useLayoutEffect(() => {
    setHydrated(true)

    // Check document
    if (document.readyState === "complete") {
      onDocReady()
    } else {
      window.addEventListener("load", onDocReady)
    }
  }, [])

  const [done, setDone] = useState<boolean>(false)
  const onPreloaderRest = useCallback(() => setDone(true), [])

  return (
    <LoadingContext.Provider value={{ loading: done }}>
      <ActualPreloader
        canGoAway={documentReady && fontsLoaded}
        showLetters={hydrated}
        onRest={onPreloaderRest}
      />
      {props.children}
    </LoadingContext.Provider>
  )
}
