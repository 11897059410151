import styled from "styled-components"

export const OrderedList = styled.ol`
  list-style-type: none;
  counter-reset: li;

  > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.775rem;
    min-height: 42px;
    :before {
      counter-increment: li;
      content: counter(li, decimal-leading-zero);
      color: ${props => props.theme.primaryColor};
      line-height: 36px;
      font-size: 1.71em;
      font-weight: bold;
      margin-right: 1.875rem;
      display: block;
    }
  }

  > li:last-child {
    margin-bottom: 0;
  }
`
