import React, { InputHTMLAttributes } from "react"
import * as S from "./styled"

type InputProps = {
  containerAs?: any
  suffix?: JSX.Element
  inputRef?: any
} & InputHTMLAttributes<HTMLInputElement>

export const Input: React.FC<InputProps> = ({
  containerAs,
  suffix,
  inputRef,
  ...props
}) => (
  <S.Container as={containerAs}>
    <S.Input ref={inputRef} {...props} />
    {suffix && <S.SuffixContainer>{suffix}</S.SuffixContainer>}
  </S.Container>
)
