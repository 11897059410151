import React from "react"
import * as S from "./styled"
import { NewsLetter } from "./NewsLetter"
import { Sitemap } from "./Sitemap"
import { CopyrightStrip } from "./CopyrightStrip"
import { graphql, useStaticQuery } from "gatsby"
import { DatoCmsAction, DatoCmsActionProps } from "../DatoCmsAction"

export const Footer: React.FC = () => {
  const {
    data: {
      newsletterOverline,
      newsletterTitle,
      newsletterFormName,
      newsletterFormHint,
      successMessage,
      socialMediaTitle,
      socialMediaLinks,
      address,
      copyright,
      linkBlocks,
      termsAndConditions,
      errorMessage,
      formInvalidMessage,
    },
  } = useStaticQuery(footerStaticQuery)
  return (
    <S.Container>
      <NewsLetter
        overline={newsletterOverline}
        title={newsletterTitle}
        formName={newsletterFormName}
        hint={newsletterFormHint}
        successMessage={successMessage}
        errorMessage={errorMessage}
        formInvalidMessage={formInvalidMessage}
      />
      <S.LinkContainer>
        <div>
          <div className="title">{socialMediaTitle}</div>
          {(socialMediaLinks as DatoCmsActionProps[]).map(a => (
            <DatoCmsAction key={a.id} plain {...a} />
          ))}
        </div>
      </S.LinkContainer>
      <Sitemap address={address} links={linkBlocks} />
      <CopyrightStrip
        copyright={copyright}
        termsAndConditions={termsAndConditions}
      />
    </S.Container>
  )
}

const footerStaticQuery = graphql`
  query footerData {
    data: datoCmsFooter {
      newsletterOverline
      newsletterTitle
      newsletterFormName
      newsletterFormHint
      successMessage
      errorMessage
      formInvalidMessage
      socialMediaTitle
      socialMediaLinks {
        ...ActionData
      }
      address {
        id
        name
        address
        email
        phone
      }
      copyright {
        ...ActionData
      }
      termsAndConditions {
        ...ActionData
      }
      linkBlocks {
        id
        titleAction {
          ...ActionData
        }
        links {
          ...ActionData
        }
      }
    }
  }
`
