import styled, { css } from "styled-components"
import device from "../Theme/devices"
import { colors } from "../Theme/colors"

export const Container = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  background: ${colors.veryPaleGreen};

  word-break: break-word;
  hyphens: auto;

  > :first-child {
    order: 100000000;
    margin-top: 40px;
  }

  @media (${device.desktop}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 65px 30px 31px;
    grid-column-gap: 10px;

    > :nth-child(1) {
      grid-row: 1;
      grid-column: 2 / 4;
      margin-top: 0;
    }

    > :nth-child(2) {
      grid-row: 1;
      grid-column: 5 / 7;
    }

    > :nth-child(3) {
      grid-row: 1;
      grid-column: 7 / 9;
    }

    > :nth-child(4) {
      grid-row: 1;
      grid-column: 9 / 11;
    }

    > :nth-child(5) {
      grid-row: 1;
      grid-column: 11 / 13;
    }
  }
`

export const titleCss = css`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
`

export const Title = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
  border-bottom: 1px solid #d6d4c9;
  padding: 15px 0 10px 0;

  @media (${device.desktop}) {
    ${titleCss};
    border-bottom: none;
    padding: 0;
  }
`

export const AddressTitle = styled.div`
  ${titleCss}
`

export const LinksEmptyLine = styled.br`
  display: none;
  @media (${device.desktop}) {
    display: inline-block;
  }
`

export const Links = styled.div<{ open: boolean }>`
  max-height: ${props => (props.open ? 275 : 0)}px;
  overflow: hidden;
  transition: all 350ms ease-out;
  display: flex;
  flex-direction: column;

  > a {
    padding: 10px 0 15px;

    :first-child {
      margin-top: 10px;
    }
    :last-child {
      margin-bottom: 20px;
    }
  }

  @media (${device.desktop}) {
    max-height: none;
    > a {
      padding: 0;

      :first-child,
      :last-child {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
`

export const Item = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;

  p:first-child {
    margin-top: 0;
  }

  :focus ${Links} {
    max-height: 200px;
  }
`
