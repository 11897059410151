export const colors = {
  almostBlackBlue: "#00092a",
  beige: "#ede8d0",
  darkerBlue: "#2421B8",
  blue: "#0530CE",
  lighterBlue: "#0047FF",
  red: "#FF5400",
  gray: "#818387",
  darkGray: "#807F7A",
  lighterGray: "#B9B9B9",
  veryPaleGreen: "#F6F5EF",
}
