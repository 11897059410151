import React, { MutableRefObject, useEffect, useRef, useState } from "react"

import * as S from "./styled"
// @ts-ignore
import { ReactComponent as LoginIcon } from "../../vector/ic-user.svg"
// @ts-ignore
import { ReactComponent as Letters } from "../../vector/letters.svg"
// @ts-ignore
import { ReactComponent as Logo } from "../../vector/logo.svg"
import { graphql, useStaticQuery } from "gatsby"
import { DatoCmsAction, DatoCmsActionProps } from "../DatoCmsAction"
import { StructuredText, StructuredTextGraphQlResponse } from "react-datocms"
import { PageLink } from "../../utils/pageLink"

type HeaderProps = {
  menuOpen: boolean
  toggleMenu: (open: boolean) => void
}

export const Header: React.FC<HeaderProps> = ({ menuOpen, toggleMenu }) => {
  const { data, vacancies, promotion } =
    useStaticQuery<headerStaticQueryType>(headerStaticQuery)

  const [desktopMenuState, setDesktopMenuState] = useState<
    "initial" | "hidden" | "scrolling up"
  >("initial")

  const lastScroll = useRef<number>(0) as MutableRefObject<number>

  useEffect(() => {
    const onScroll = () => {
      const y = window.pageYOffset
      if (y < 115) {
        setDesktopMenuState("initial")
      } else if (lastScroll.current <= y) {
        // going down
        setDesktopMenuState("hidden")
      } else {
        // going up
        setDesktopMenuState("scrolling up")
      }
      lastScroll.current = y
    }
    onScroll()
    window.addEventListener("scroll", onScroll, { passive: true })
    return () => window.removeEventListener("scroll", onScroll, true)
  }, [])

  return (
    <S.Container>
      {promotion.headerBarEnabled && (
        <S.HeaderBar>
          <StructuredText
            data={promotion.headerBarContent}
            renderLinkToRecord={rec => (
              <DatoCmsAction
                link={[{ __typename: "DatoCmsPageLink", page: rec.record }]}
                // @ts-ignore
                content={rec.children}
              />
            )}
          />
        </S.HeaderBar>
      )}
      <S.MobileHeader>
        <DatoCmsAction
          id={"logo"}
          style={"regular"}
          label={"Home"}
          //@ts-ignore
          onClick={() => toggleMenu(false)}
          link={[
            {
              __typename: "DatoCmsPageLink",
              //@ts-ignore
              page: { __typename: "DatoCmsPage", path: "" },
            },
          ]}
          content={<Logo />}
        />
        <S.MenuWrapper onClick={() => toggleMenu(!menuOpen)}>
          {menuOpen ? "Sluiten" : "Menu"}
          <S.MenuIcon close={menuOpen} />
        </S.MenuWrapper>
      </S.MobileHeader>
      <S.LoginContainer>
        {data.loginAction.map((a, i) => (
          <DatoCmsAction
            key={i}
            {...a}
            content={
              <S.LoginButton>
                {a.label}
                {a.label === "Vacatures" && (
                  <S.Counter>{vacancies.totalCount}</S.Counter>
                )}
                {i === data.loginAction.length - 1 && <LoginIcon />}
              </S.LoginButton>
            }
          />
        ))}
      </S.LoginContainer>
      <S.HeaderContainer state={desktopMenuState}>
        <S.LogoContainer
          as={DatoCmsAction}
          id={"logo"}
          style={"regular"}
          label={"Home"}
          link={[
            {
              __typename: "DatoCmsPageLink",
              //@ts-ignore
              page: { __typename: "DatoCmsPage", path: "" },
            },
          ]}
          content={
            <>
              <Logo />
              <Letters />
            </>
          }
        />
        <S.HeaderLinksContainer>
          {data.links.map(l => (
            <React.Fragment key={l.id}>
              <DatoCmsAction {...l} plain />
              {`          `}
            </React.Fragment>
          ))}
        </S.HeaderLinksContainer>
        <DatoCmsAction {...data.action} />
      </S.HeaderContainer>
    </S.Container>
  )
}

type headerStaticQueryType = {
  data: {
    links: DatoCmsActionProps[]
    action: DatoCmsActionProps
    loginAction: DatoCmsActionProps[]
  }
  vacancies: {
    totalCount: number
  }
  promotion: {
    headerBarEnabled: boolean
    headerBarContent: StructuredTextGraphQlResponse<PageLink>
  }
}

const headerStaticQuery = graphql`
  query headerData {
    vacancies: allDatoCmsJobVacancy {
      totalCount
    }
    promotion: datoCmsPromotion {
      headerBarEnabled
      headerBarContent {
        value
        links {
          ...PageLink
          ...StoriesOverviewLink
          ...BlogLink
          ...FormPageLink
          ... on DatoCmsBlogPost {
            __typename
            id: originalId
            path
          }
          ... on DatoCmsEntrepreneurialStory {
            __typename
            id: originalId
            path
          }
          ... on DatoCmsJobVacancy {
            __typename
            id: originalId
            path
          }
        }
      }
    }
    data: datoCmsAppHeader {
      links {
        ...ActionData
      }
      action {
        ...ActionData
      }
      loginAction {
        ...ActionData
      }
    }
  }
`
