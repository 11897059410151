import React, { useEffect, useState } from "react"
import {
  DatoCmsAsset as IDatoCmsAsset,
  DatoCmsVideoAsset,
} from "./DatoCmsAsset"
import * as S from "./styled"
import ReactPlayer from "react-player"
import { useInView } from "react-intersection-observer"
import { animated } from "react-spring"
import { useMedia } from "use-media"
import { size } from "../Theme/devices"

//@ts-ignore
import { ReactComponent as SoundOff } from "../../vector/volume_off_black_24dp.svg"
//@ts-ignore
import { ReactComponent as SoundOn } from "../../vector/volume_up_black_24dp.svg"
import styled from "styled-components"

export const DatoCmsAsset: React.FC<
  IDatoCmsAsset & { [other: string]: any }
> = props => {
  if (props.isImage) {
    if (props.fluid) return <S.AGatsbyImage {...props} />
    return <S.LegacyImage {...props} />
  } else {
    const autoplay = isTrue(props.customData?.autoplay)
    const loop = isTrue(props.customData?.loop)

    if (autoplay) {
      return (
        <EagerVideo
          loop={loop}
          onlyOnHover={isTrue(props.customData?.playOnHover)}
          waitForScroll={isTrue(props.customData?.waitForScroll)}
          allowUnmute={isTrue(props.customData?.allowUnmute)}
          {...props}
        />
      )
    }

    return <LazyVideo loop={loop} {...props} />
  }
}

const MuteButton: React.FC<{
  muted: boolean
  toggle: (muted: boolean) => void
}> = ({ muted, toggle }) => (
  <S.MuteButtonContainer onClick={() => toggle(!muted)}>
    {muted ? <SoundOn /> : <SoundOff />}
  </S.MuteButtonContainer>
)

const EagerVideo: React.FC<
  DatoCmsVideoAsset & {
    loop?: boolean
    onlyOnHover?: boolean
    waitForScroll?: boolean
    allowUnmute?: boolean
  }
> = ({
  video,
  width,
  height,
  loop,
  onlyOnHover,
  allowUnmute,
  waitForScroll,
  ...props
}) => {
  const isDesktop = useMedia({ minWidth: size.desktop })
  const { inView, ref } = useInView({
    threshold: onlyOnHover ? (isDesktop ? 0.5 : 1) : 0.5,
  })
  const [isHovered, setHovered] = useState<boolean>(false)

  const [didScroll, setDidScroll] = useState<boolean>(false)

  useEffect(() => {
    if (didScroll) return undefined
    const onScroll = () => setDidScroll(window.scrollY > 50)
    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [didScroll])

  const playing =
    (waitForScroll ? didScroll : true) &&
    (onlyOnHover ? (!isDesktop || isHovered) && inView : inView)

  const [muted, setMuted] = useState<boolean>(true)

  return (
    <VideoWrapper
      ref={ref}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...props}
    >
      <ReactPlayer
        width={"100%"}
        height={"100%"}
        url={video.mp4Url + "#t=0.1"}
        playing={playing}
        loop={loop}
        controls={!loop}
        muted={muted}
        playsinline={true}
      />
      {allowUnmute && <MuteButton muted={muted} toggle={setMuted} />}
    </VideoWrapper>
  )
}

const VideoWrapper = styled(animated.div)`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  video {
    display: block;
  }
`

const LazyVideo: React.FC<DatoCmsVideoAsset & { loop?: boolean }> = ({
  width,
  height,
  video,
  loop,
  ...props
}) => {
  const [clickedPreview, setClickedPreview] = useState<boolean>(false)

  return (
    <S.LazyWrapper width={width} height={height} {...props}>
      <ReactPlayer
        className="video-player"
        width={"100%"}
        height={"100%"}
        url={video.mp4Url}
        light={video.thumbnailUrl}
        onClickPreview={() => setClickedPreview(true)}
        playing={clickedPreview}
        loop={loop}
        controls={!loop}
      />
    </S.LazyWrapper>
  )
}

const isTrue = (val: string | undefined) =>
  ["true", "1", "yes", "ja", "sim", "s", "y", "j"].includes(
    (val || "").toLowerCase().trim()
  )
