import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  max-width: 100%;
  position: relative;
  align-items: center;

  :after {
    content: "";
    height: 4px;
    background: currentColor;
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`

export const Input = styled.input`
  background: none;
  outline: none;
  color: inherit;
  border: none;
  padding: 14px 7px;
  flex: 1;
  max-width: 100%;

  ::placeholder {
    color: currentColor;
    opacity: 50%;
  }
`

export const SuffixContainer = styled.div`
  margin-bottom: 10px;
  margin-right: 10px;
`
