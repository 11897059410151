import styled, { css } from "styled-components"
import device from "../Theme/devices"
import { colors } from "../Theme/colors"
import { Body2 } from "../Texts"

export const Container = styled.div<{ block?: boolean; done?: boolean }>`
  background: ${props => props.theme.primaryColor};
  border-top-right-radius: 30px;
  padding: 53px 20px 60px;
  color: ${colors.veryPaleGreen};
  @media (${device.tablet}) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    padding-left: 30px;
  }
  > * {
    transition: all 250ms ease-out;
  }

  ${props =>
    props.block &&
    css`
      pointer-events: none;
      > * {
        opacity: 0.5;
        pointer-events: none;
      }
    `}

  ${props =>
    props.done
      ? css`
          > :nth-child(2) {
            opacity: 0;
            visibility: hidden;
          }
          > :nth-child(3) {
            grid-row: 2;
            grid-column: 2 / 7;
            display: block;
            opacity: 1;
            margin-top: auto;
          }
        `
      : css`
          > :nth-child(3) {
            display: none;
          }
        `}
`

export const Text = styled.div`
  padding-bottom: 7px;
  @media (${device.tablet}) {
    grid-column: 2 / 6;
    grid-row: 1;
    max-width: 380px;

    > :first-child {
      margin-bottom: 4px;
    }
  }
`

export const Error = styled(Body2)`
  grid-row: 3;
  grid-column: 2 / 7;
  padding-top: 5px;
`

export const InputContainer = styled.div`
  grid-row: 2;
  grid-column: 2 / 7;
`

export const Overline = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
  display: block;
`

export const Header = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
`
