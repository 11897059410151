import React, { useState } from "react"
import { Normalize } from "styled-normalize"
import { StyleSheetManager, ThemeProvider } from "styled-components"
import { Header } from "../Header"
import { AppTheme } from "../Theme"
import { GlobalStyles } from "../Theme/global"
import { Footer } from "../Footer"
import { Menu } from "../Menu"
// @ts-ignore
import { Helmet } from "react-helmet"
import { WithConfigurationContext } from "../../context/configuration"
import { WithReadMoreCursorContext } from "../../context/readMoreCursor"
import { ParallaxProvider } from "react-scroll-parallax"
import { Preloader } from "../Preloader"
import griddie from "styled-griddie"
import { CookieBanner } from "../../CookieBanner"
import { PromotionPopup } from "../PromotionPopup"

export const PageWrapper: React.FC = ({ children, ...props }) => {
  const [mobileMenuOpen, setMenuOpen] = useState<boolean>(false)


  return (
    <StyleSheetManager stylisPlugins={[griddie]}>
      <ThemeProvider theme={AppTheme}>
        <Helmet htmlAttributes={{ lang: "nl" }}>
          <link rel="stylesheet" href="/fonts/beatrice.css" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
            rel="preload"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
            rel="stylesheet"
            media="screen"
          />
        </Helmet>
        <Normalize />
        <GlobalStyles />
        <WithConfigurationContext>
          <Preloader>
            <ParallaxProvider>
              <Header menuOpen={mobileMenuOpen} toggleMenu={setMenuOpen} />
              <Menu open={mobileMenuOpen} close={setMenuOpen} />
              <PromotionPopup />
              <WithReadMoreCursorContext>
                {children}
                <CookieBanner />
              </WithReadMoreCursorContext>
              <Footer />
            </ParallaxProvider>
          </Preloader>
        </WithConfigurationContext>
      </ThemeProvider>
    </StyleSheetManager>
  )
}

export default PageWrapper
