import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StructuredText, StructuredTextGraphQlResponse } from "react-datocms"
import { PageLink } from "../../utils/pageLink"
import { DatoCmsAction } from "../DatoCmsAction"
import type { DatoCmsAsset as IDatoCmsAsset } from "../DatoCmsAsset/DatoCmsAsset"
import { DatoCmsAsset } from "../DatoCmsAsset"
import * as S from "./styled"
import ScrollLock from "react-scrolllock"
import { Button } from "../Buttons"

import { globalHistory } from "@reach/router"

export const PromotionPopup: React.FC = () => {
  const [rendered, setRendered] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  useEffect(() => setRendered(true), [])
  useEffect(() => setVisible(true), [])

  const {
    promotion: { enabled, content, image, popupCta },
  } = useStaticQuery<StaticData>(promotionStaticQuery)

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") setVisible(false)
    })
  }, [visible])

  return !(enabled && rendered && visible) ? null : (
    <ScrollLock isActive accountForScrollbars>
      <S.PromotionContainer visible={visible}>
        <S.PromotionClose
          onClick={() => {
            setVisible(false)
          }}
        >
          <svg
            width="14"
            height="14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m1.5 1.5 11 11m-11 0 11-11"
              stroke="#00092A"
              stroke-width=".8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </S.PromotionClose>
        {image && <DatoCmsAsset {...image} />}
        <S.PromotionText>
          <StructuredText
            data={content}
            renderLinkToRecord={rec => {
              return (
                <DatoCmsAction
                  link={[{ __typename: "DatoCmsPageLink", page: rec.record }]}
                  // @ts-ignore
                  content={rec.children}
                />
              )
            }}
          />
          <S.AlignRight>
            <Button>
              <a
                href={popupCta[0]?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {popupCta[0]?.label}
              </a>
            </Button>
          </S.AlignRight>
        </S.PromotionText>
      </S.PromotionContainer>
    </ScrollLock>
  )
}

type StaticData = {
  promotion: {
    enabled: boolean
    content: StructuredTextGraphQlResponse<PageLink>
    image: IDatoCmsAsset
    popupCta: [
      {
        label: string
        url: string
      }
    ]
  }
}

const promotionStaticQuery = graphql`
  query promotionPopUpData {
    promotion: datoCmsPromotion {
      enabled: popUpEnabled
      image: popUpHeader {
        ...DatoCmsAssetData
      }
      popupCta {
        label
        url
      }
      content: popUpContent {
        value
        links {
          ...PageLink
          ...StoriesOverviewLink
          ...BlogLink
          ...FormPageLink
          ... on DatoCmsBlogPost {
            __typename
            id: originalId
            path
          }
          ... on DatoCmsEntrepreneurialStory {
            __typename
            id: originalId
            path
          }
          ... on DatoCmsJobVacancy {
            __typename
            id: originalId
            path
          }
        }
      }
    }
  }
`
