import React from "react"
import styled from "styled-components"
import HtmlToReact, {
  ProcessNodeDefinitions,
  TProcessingInstruction,
} from "html-to-react"
import { Link } from "gatsby"
import { OrderedList } from "../Lists"
import { PageTitle, SectionTitle } from "../Texts"

type DatoCmsHtmlTextProps = {
  html: string
  replace?: ReplacementsDef
  [other: string]: any
}

export type ReplacementsDef = {
  [htmlElement: string]: React.FC
}

const defaultReplacements: ReplacementsDef = {
  ol: OrderedList,
  h1: PageTitle,
  h2: SectionTitle,
}

export const DatoCmsHtmlText: React.FC<DatoCmsHtmlTextProps> & { cta?: React.ReactNode } = ({
  html,
  replace = {},
  cta,
  ...props
}) => {
  const parser = new HtmlToReact.Parser()

  const processNodeDefinitions = new ProcessNodeDefinitions(React)

  const replacements = { ...defaultReplacements, ...replace }

  const processingInstructions: TProcessingInstruction[] = [
    {
      replaceChildren: true,
      shouldProcessNode: node => {
        return (
          node.attribs &&
          node.attribs["href"] &&
          node.attribs["href"].startsWith("/")
        )
      },
      processNode: function (node, children) {
        return <Link to={node.attribs["href"]}>{children}</Link>
      },
    },
    {
      shouldProcessNode: node => !!replacements[node.name],
      processNode: (node, children) => {
        const C = replacements[node.name]!
        return <C>{children}</C>
      },
    },
    {
      shouldProcessNode: node => true,
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ]
  return (
    <Container className="cmsTextHtml" {...props}>
      {parser.parseWithInstructions(html, () => true, processingInstructions)}
      {cta && <CtaContainer>{cta}</CtaContainer>}
    </Container>
  )
}

const Container = styled.div`
  h2:first-child {
    margin-top: 0;
  }
  ul[type="disc"] {
    display: flex;
    flex-direction: column;
    gap: 26px;
    list-style: none;
    padding: 0;
    margin: 1.5em 0;
    li {
      position: relative;
      padding: 8px 0 0 51px;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 16px;
      white-space: normal;
      min-height: ${67 - 26}px;
      p {
        margin: 0;
      }
      &:before {
        display: block;
        content: '';
        height: 34px;
        width: 34px;
        position: absolute;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="none"><path stroke="%2300092A" stroke-linecap="round" stroke-width="2" d="m12 18 3.3 3.3 7.4-7.3"/><circle cx="17" cy="17" r="16" stroke="%2300092A" stroke-width="2"/></svg>');
        left: 0;
        top: 0;
      }
    }
  }
`

const CtaContainer = styled.div`
  margin-top: 1.5em;
`