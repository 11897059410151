import React, { useCallback } from "react"
import { useTransition } from "react-spring"
import * as S from "./styled"
import { graphql, useStaticQuery } from "gatsby"
import { IconButton } from "../Buttons"
//@ts-ignore
import { ReactComponent as CallIcon } from "../../vector/ic-call.svg"
import { useMedia } from "use-media"
import { size } from "../Theme/devices"
import { Address } from "../../types/Address"
import { DatoCmsAction, DatoCmsActionProps } from "../DatoCmsAction"
import { DatoCmsHtmlText } from "../DatoCmsHtmlText"
import ScrollLock, { TouchScrollable } from "react-scrolllock"

type MenuProps = {
  open: boolean
  close: (open: boolean) => void
}

export const Menu: React.FC<MenuProps> = props => {
  const isTablet = useMedia({ minWidth: size.desktop })
  const transitions = useTransition(props.open && !isTablet, {
    from: {
      opacity: 0,
    },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const { data } = useStaticQuery<menuStaticQueryType>(menuStaticQuery)

  const close = useCallback(() => props.close(false), [props.close])

  return (
    <>
      <ScrollLock isActive={props.open && !isTablet} />

      {transitions(
        (style, item) =>
          item && (
            <TouchScrollable>
              {/*@ts-ignore*/}
              <S.MenuContainer style={style}>
                <S.ItemsContainer>
                  {data.links.map(l => (
                    <DatoCmsAction
                      key={l.id}
                      plain
                      //@ts-ignore
                      onClick={close}
                      skipTransition
                      {...l}
                    />
                  ))}
                </S.ItemsContainer>
                <S.SmallItemsContainer>
                  {data.smallLinks?.map(l => (
                    <React.Fragment key={l.id}>
                      <DatoCmsAction
                        key={l.id}
                        plain
                        //@ts-ignore
                        onClick={close}
                        skipTransition
                        {...l}
                      />
                      <br />
                    </React.Fragment>
                  ))}
                </S.SmallItemsContainer>
                <S.FollowUs>
                  <DatoCmsHtmlText as={S.Address} html={data.address.address} />
                  <b>{data.socialMediaLabel}</b> <br />
                  {data.socialMediaLinks.map(l => (
                    <DatoCmsAction key={l.id} plain {...l} />
                  ))}
                </S.FollowUs>
                <S.ContactContainer>
                  <a href={`tel:${data.address.phone}`}>
                    <IconButton big dark>
                      <CallIcon />
                    </IconButton>
                  </a>
                  {/*@ts-ignore*/}
                  <DatoCmsAction onClick={close} {...data.bottomRightAction} />
                </S.ContactContainer>
              </S.MenuContainer>
            </TouchScrollable>
          )
      )}
    </>
  )
}

type menuStaticQueryType = {
  data: {
    address: Address
    links: DatoCmsActionProps[]
    smallLinks: DatoCmsActionProps[]
    socialMediaLabel: string
    socialMediaLinks: DatoCmsActionProps[]
    bottomRightAction: DatoCmsActionProps
  }
}

const menuStaticQuery = graphql`
  query menuData {
    data: datoCmsMenu {
      address {
        id
        name
        address
        email
        phone
      }
      links {
        ...ActionData
      }
      smallLinks {
        ...ActionData
      }
      socialMediaLabel
      socialMediaLinks {
        ...ActionData
      }
      bottomRightAction {
        ...ActionData
      }
    }
  }
`
