import styled, { css } from "styled-components"
import { colors } from "../Theme/colors"
import device from "../Theme/devices"

export const Container = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 20;

  @media (${device.desktop}) {
    top: -46px;
  }
`
export const HeaderBar = styled.div`
  background: #ffee59;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5em;
  padding: 0.89em;
  p {
    margin: 0;

    a {
      text-decoration: underline;
    }
  }
`
export const MobileHeader = styled.div`
  background: ${colors.almostBlackBlue};
  color: ${colors.beige};
  position: sticky;
  top: 0;
  padding: 14px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: right;

  a {
    margin-right: auto;
    line-height: 0;
  }

  @media (${device.desktop}) {
    display: none;
  }
`

export const MenuWrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  padding: 10px 0;
  color: inherit;
`

export const MenuIcon = styled.div<{ close: boolean }>`
  width: 22px;
  height: 1px;
  position: relative;
  background: ${props => (props.close ? "transparent" : "currentColor")};
  border-radius: 1px;
  margin-left: 16px;
  transition: all 250ms ease-out;

  :before {
    content: "";
    position: absolute;
    ${props =>
      props.close
        ? css`
            transform: rotate(45deg);
          `
        : css`
            top: -6px;
          `}
    width: 100%;
    height: 100%;
    background: currentColor;
    border-radius: 1px;
    left: 0;
    transition: all 250ms ease-out;
  }

  :after {
    content: "";
    position: absolute;
    ${props =>
      props.close
        ? css`
            transform: rotate(-45deg);
          `
        : css`
            bottom: -6px;
          `}
    width: 100%;
    height: 100%;
    background: currentColor;
    border-radius: 1px;
    left: 0;
    transition: all 250ms ease-out;
  }
`

export const LoginContainer = styled.div`
  display: none;
  @media (${device.desktop}) {
    background: ${colors.almostBlackBlue};
    color: ${colors.beige};
    padding: 7px 32px 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0;
    text-align: right;

    svg {
      margin-left: 16px;
    }

    a {
      margin-left: 20px;
    }

    a:first-child {
      margin-left: auto;
    }
  }
`

export const LoginButton = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

export const Counter = styled.div`
  margin-left: 4px;
  margin-top: -7px;
  background: ${props => props.theme.secondaryColor};
  width: 21px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
`

export const LogoContainer = styled.a`
  display: flex;
  align-items: center;

  > :last-child {
    position: absolute;
  }

  > * {
    transition: all 250ms ease-out;
  }
`

export const HeaderContainer = styled.div<{
  state: "initial" | "hidden" | "scrolling up"
}>`
  display: none;

  @media (${device.desktop}) {
    position: absolute;
    top: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 23px 32px 0 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 250ms ease-out;
    background-color: transparent;

    > :first-child {
      margin-top: 3px;
      transform: scale(120%);
    }

    > :nth-child(2) {
      margin-left: auto;
    }

    ${LogoContainer} {
      > :last-child {
        opacity: 0;
      }
    }

    ${props =>
      props.state === "hidden"
        ? css`
            opacity: 0;
          `
        : props.state === "scrolling up" &&
          css`
            background-color: white;
            padding-top: 13px;
            padding-bottom: 13px;
            ${LogoContainer} {
              > :first-child {
                opacity: 0;
              }
              > :last-child {
                opacity: 1;
              }
            }
          `}
  }
`

export const HeaderLinksContainer = styled.div`
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: right;
  white-space: pre;

  a {
    color: inherit;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`
