import React, { useCallback, useMemo, useState } from "react"
import * as S from "./NewsLetter.styled"
import { Input } from "../Input"
import { IconButton } from "../Buttons"
//@ts-ignore
import { ReactComponent as ArrowRight } from "../../vector/ic-arrow-right.svg"
import { useForm } from "react-hook-form"
import { fetch } from "cross-fetch"
import { SlideInTextAnimation } from "../SlideInTextAnimation"

export const NewsLetter: React.FC<{
  overline: string
  title: string
  formName: string
  hint: string
  successMessage: string
  errorMessage: string
  formInvalidMessage: string
}> = ({
  overline,
  title,
  formName,
  hint,
  successMessage,
  formInvalidMessage,
  errorMessage,
}) => {
  const { register, handleSubmit } = useForm()
  const [formState, setFormState] = useState<
    "invalid" | "submitting" | "submitted" | "error" | undefined
  >(undefined)

  const onSubmit = useMemo(
    () =>
      handleSubmit(
        ({ email }) => {
          setFormState("submitting")
          fetch(`/.netlify/functions/newsletter-subscribe`, {
            method: "post",
            body: JSON.stringify({
              email,
            }),
          })
            .then(() => setFormState("submitted"))
            .catch(e => {
              console.log(e)
              setFormState("error")
            })
        },
        () => setFormState("invalid")
      ),
    [handleSubmit]
  )

  return (
    <S.Container
      as={"form"}
      block={formState === "submitting"}
      done={formState === "submitted"}
      onSubmit={onSubmit}
    >
      <S.Text>
        <S.Overline>{overline}</S.Overline>
        <S.Header>{title}</S.Header>
      </S.Text>
      <Input
        inputRef={register({ required: true })}
        type={"email"}
        name={"email"}
        containerAs={S.InputContainer}
        placeholder={hint}
        suffix={
          <IconButton type="submit" title="Schrijf je in op de nieuwsbrief">
            <ArrowRight />
          </IconButton>
        }
      />
      <SlideInTextAnimation
        text={successMessage}
        pause={formState !== "submitted"}
      />
      {formState === "invalid" && <S.Error>{formInvalidMessage}</S.Error>}
      {formState === "error" && <S.Error>{errorMessage}</S.Error>}
    </S.Container>
  )
}
