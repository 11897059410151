import styled from "styled-components"
import { animated } from "react-spring"
import GatsbyImageWithIEPolyfill from "gatsby-image/withIEPolyfill"

export const AGatsbyImage = styled(animated(GatsbyImageWithIEPolyfill))``

export const LegacyImage = styled(animated.img)`
  width: 100%;
  height: 100%;
  display: block;
`

export const LazyWrapper = styled(animated.div)<{
  width: number
  height: number
}>`
  position: relative;
  padding-top: ${props => 100 / (props.width / props.height)}%;

  .video-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const MuteButtonContainer = styled.button`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 26px;
  left: 20px;
  border: none;
  outline: none;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    overflow: visible;
  }
`
