import styled, { css, DefaultTheme } from "styled-components"
import { colors } from "../Theme/colors"

const getBackgroundColor = (props: ButtonProps & { theme: DefaultTheme }) => {
  if (props.dark) {
    return props.theme.primaryColorDarker
  }
  if (props.gray) {
    return "#DEDCCF"
  }
  if (props.secondary) return props.theme.secondaryColor

  return props.theme.primaryColor
}

export const ButtonComponent = styled.button<ButtonProps>`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 13px 26px 12px 26px;
  border-radius: 23px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: center;
  transition: all 250ms ease-out;
  position: relative;
  overflow: hidden;

  > #hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00092a;

    border-radius: 23px;
    color: white;
    //transition: 0.55s transform cubic-bezier(0.165, 0.84, 0.44, 1),
    //  0.4s background-color cubic-bezier(0.165, 0.84, 0.44, 1),
    //  0.55s -webkit-transform cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 0;
  }
  ${(props) => props.minimal ? css`
    background: transparent;
    color: ${colors.blue};
    :hover {
      color: ${colors.red};
    }
  ` : css`
    background: ${getBackgroundColor};
    color: ${props => (!props.gray ? "white" : props.theme.textColor)};
    :hover {
      color: white;
    }
  `}
  ${props =>
    props.disabled &&
    css`
      opacity: 0.2;
      pointer-events: none;
    `}
`

export const ChildrenContainer = styled.div`
  z-index: 1;
  position: relative;
`

export const IconButton = styled.button<{ big?: boolean; dark?: boolean }>`
  background: ${({ dark, theme }) =>
    dark ? theme.primaryColorDarker : theme.secondaryColor};
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  width: ${props => (props.big ? 46 : 35)}px;
  height: ${props => (props.big ? 46 : 35)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`
