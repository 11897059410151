import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

export type ConfigurationContextType = {
  storiesParentPath: string
  jobVacanciesParentPath: string
  teamMembersParentPath: string
  blogPostsParentPath: string
}

const defaultValue = {
  storiesParentPath: "",
  jobVacanciesParentPath: "",
  teamMembersParentPath: "",
  blogPostsParentPath: "",
}

const ConfigurationContext = React.createContext<ConfigurationContextType>(
  defaultValue
)

export const useConfiguration = () => {
  return useContext(ConfigurationContext)
}

export const WithConfigurationContext: React.FC = props => {
  const { config } = useStaticQuery(graphql`
    {
      config: datoCmsConfiguration {
        storiesParentPage {
          path
        }
        jobVacanciesParentPage {
          path
        }
        teamMembersParentPage {
          path
        }
        blogPostsParentPage {
          path
        }
      }
    }
  `)
  return (
    <ConfigurationContext.Provider
      value={{
        storiesParentPath: config.storiesParentPage.path,
        jobVacanciesParentPath: config.jobVacanciesParentPage.path,
        teamMembersParentPath: config.teamMembersParentPage.path,
        blogPostsParentPath: config.blogPostsParentPage.path,
      }}
    >
      {props.children}
    </ConfigurationContext.Provider>
  )
}
