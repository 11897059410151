// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/Default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-form-page-tsx": () => import("./../../../src/templates/FormPage.tsx" /* webpackChunkName: "component---src-templates-form-page-tsx" */),
  "component---src-templates-job-vacancy-tsx": () => import("./../../../src/templates/JobVacancy.tsx" /* webpackChunkName: "component---src-templates-job-vacancy-tsx" */),
  "component---src-templates-stories-overview-tsx": () => import("./../../../src/templates/StoriesOverview.tsx" /* webpackChunkName: "component---src-templates-stories-overview-tsx" */),
  "component---src-templates-story-tsx": () => import("./../../../src/templates/Story.tsx" /* webpackChunkName: "component---src-templates-story-tsx" */),
  "component---src-templates-team-member-tsx": () => import("./../../../src/templates/TeamMember.tsx" /* webpackChunkName: "component---src-templates-team-member-tsx" */)
}

