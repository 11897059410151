import styled from "styled-components"
import device from "../Theme/devices"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
  padding: 20px;

  @media (${device.desktop}) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 10px;
    align-items: end;
    padding: 15px 30px 20px;
  }
`

export const Copyright = styled.div`
  grid-row: 1;
  grid-column: 1;
  padding-top: 7px;

  @media (${device.desktop}) {
    grid-row: 1;
    grid-column: 2 / 5;
    padding-top: 0;
  }

  div {
    width: 100%;
  }
`

export const TermsAndConditions = styled.div`
  grid-row: 2;
  grid-column: 1;
  @media (${device.desktop}) {
    grid-row: 1;
    grid-column: 5 / 9;
  }

  div {
    width: 100%;
  }
`

export const Logo = styled.div`
  grid-row: 1 / 3;
  grid-column: 2;

  svg {
    display: block;
    margin-left: auto;
  }

  @media (${device.desktop}) {
    grid-row: 1;
    grid-column: 12;
  }
`
