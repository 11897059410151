import React from "react"
import * as S from "./CopyrightStrip.styled"
// @ts-ignore
import { ReactComponent as Logo } from "../../vector/logo.svg"
import { DatoCmsAction, DatoCmsActionProps } from "../DatoCmsAction"

export const CopyrightStrip: React.FC<{
  copyright: DatoCmsActionProps
  termsAndConditions: DatoCmsActionProps
}> = ({ copyright, termsAndConditions }) => (
  <S.Container>
    <S.Copyright>
      <DatoCmsAction {...copyright} plain />
    </S.Copyright>
    <S.TermsAndConditions>
      <DatoCmsAction {...termsAndConditions} plain />
    </S.TermsAndConditions>
    <S.Logo>
      <Logo />
    </S.Logo>
  </S.Container>
)
