import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import styled from "styled-components"
import { colors } from "../components/Theme/colors"
import { useMedia } from "use-media"
import { size } from "../components/Theme/devices"
import { useThrottledCallback } from "use-debounce"

const ReadMoreCursorContext = React.createContext({
  visible: false,
  setVisible: (visible: boolean) => {},
})

export const useReadMoreCursor = () => {
  return useContext(ReadMoreCursorContext)
}

const ReadMore = styled.div`
  width: 104px;
  height: 104px;
  background: ${colors.red};
  border-radius: 52px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 250ms ease-out;
  z-index: 100;
  pointer-events: none;

  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  will-change: top, left;
`

export const WithReadMoreCursorContext: React.FC = ({ children }) => {
  const onMouseMove = useCallback((event: MouseEvent) => {
    if (readMoreRef.current) {
      readMoreRef.current.style.top = `${event.pageY - 52}px`
      readMoreRef.current.style.left = `${event.pageX - 52}px`
    }
  }, [])
  const readMoreRef = useRef<HTMLDivElement>()
  const throttledOnMouseMove = useThrottledCallback(onMouseMove, 100)
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    if (visible) {
      window.addEventListener("mousemove", throttledOnMouseMove, false)
      return () =>
        window.removeEventListener("mousemove", throttledOnMouseMove, false)
    }
  }, [visible])

  const isDesktop = useMedia({ minWidth: size.desktop })

  return (
    <ReadMoreCursorContext.Provider value={{ visible, setVisible }}>
      {children}
      {isDesktop && (
        <ReadMore
          //@ts-ignore
          ref={readMoreRef}
          style={{
            opacity: visible ? 1 : 0,
          }}
        >
          Lees meer
        </ReadMore>
      )}
    </ReadMoreCursorContext.Provider>
  )
}
