import React from "react"
import { graphql } from "gatsby"
// @ts-ignore
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Button } from "../Buttons"
import styled from "styled-components"
import { getPageUrl, PageLink } from "../../utils/pageLink"
import { colors } from "../Theme/colors"
import { StoryTopic } from "../../types/storyTopic"
import {
  ConfigurationContextType,
  useConfiguration,
} from "../../context/configuration"

export type DatoCmsActionProps = {
  id: string
  label: string | JSX.Element
  style: "regular" | "darker" | "gray" | "disabled" | "secondary"
  __typename?: "DatoCmsAction"
  link: [
    | {
        __typename: "DatoCmsPageLink"
        page: PageLink
      }
    | {
        __typename: "DatoCmsExternalUrl"
        url: string
      }
    | {
        __typename: "DatoCmsEmptyAction"
      }
    | {
        __typename: "DatoCmsSendEmail"
        to: {
          email: string
        }
      }
  ]
}

const LinkTag = styled.a`
  text-decoration: none;
  color: inherit;
`

export const defaultAnyLinkProps = {
  cover: true,
  duration: 2,
  bg: colors.veryPaleGreen,
  direction: "right",
}

export const parseDatoCmsPageLink = (
  link: PageLink,
  config: ConfigurationContextType
) => ({
  LinkComp: AniLink,
  linkProps: {
    to: getPageUrl(link, config), //TODO section id
    ...defaultAnyLinkProps,
  },
})

const parseDatoCmsActionLink = (
  link: DatoCmsActionProps["link"][0],
  config: ConfigurationContextType,
  skipTransition?: boolean
) => {
  let LinkComp, linkProps

  switch (link.__typename) {
    case "DatoCmsPageLink":
      LinkComp = AniLink
      linkProps = {
        activeClassName: "active",
        to: getPageUrl(link.page, config),
        ...(skipTransition ? {} : defaultAnyLinkProps),
      }
      break
    case "DatoCmsExternalUrl":
      if (link.url.startsWith("/")) {
        LinkComp = AniLink
        linkProps = {
          activeClassName: "active",
          to: link.url,
          ...(skipTransition ? {} : defaultAnyLinkProps),
        }

        break
      }
      LinkComp = "a"
      linkProps = {
        href: link.url,
        target: "_blank",
        rel: "noopener",
      }
      break
    case "DatoCmsSendEmail":
      LinkComp = "a"
      linkProps = {
        href: `mailto:${link.to.email}`,
      }
      break
    default:
      LinkComp = "span"
      linkProps = {}
  }
  return { Component: LinkComp, props: linkProps }
}

export const parseDatoCmsAction = (
  { link: [link] }: DatoCmsActionProps,
  config: ConfigurationContextType
) => parseDatoCmsActionLink(link, config)

export const DatoCmsAction: React.FC<
  DatoCmsActionProps & {
    content?: JSX.Element
    plain?: boolean
    forwardedAs?: any
    skipTransition?: boolean
    // Blue Text Cta
    minimal?: boolean
  }
> = ({ label, style, link: [link] = [], content, plain, minimal, ...props }) => {
  const config = useConfiguration()

  if (!link) return null
  const { Component: LinkComp, props: linkProps } = parseDatoCmsActionLink(
    link,
    config,
    props.skipTransition
  )

  return (
    //@ts-ignore
    <LinkTag as={LinkComp} {...props} {...linkProps}>
      {content ||
        (plain ? (
          label
        ) : (
          <Button
            dark={style === "darker"}
            gray={style === "gray"}
            minimal={minimal}
            disabled={style === "disabled"}
            secondary={style === "secondary"}
          >
            {label}
          </Button>
        ))}
    </LinkTag>
  )
}

export const DatoCmsStoryTopicAction: React.FC<
  StoryTopic & ButtonProps & { clear?: boolean }
> = props => {
  const config = useConfiguration()
  return (
    <AniLink
      to={getPageUrl(
        props.clear
          ? {
              ...props,
              path: "",
            }
          : props,
        config
      )}
      {...defaultAnyLinkProps}
    >
      <Button {...props}>{props.name}</Button>
    </AniLink>
  )
}

export const fragment = graphql`
  fragment ActionData on DatoCmsAction {
    id
    __typename
    label
    style
    link {
      __typename
      ... on DatoCmsPageLink {
        page {
          ...PageLink
          ...FormPageLink
          ...StoriesOverviewLink
          ...BlogLink
        }
      }
      ... on DatoCmsExternalUrl {
        url
      }
      ... on DatoCmsSendEmail {
        to {
          email
        }
      }
    }
  }
`
