import styled from "styled-components"
import device from "../Theme/devices"
import { colors } from "../Theme/colors"

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  z-index: 5;
  position: relative;
  @media (${device.tablet}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    > :nth-child(1) {
      z-index: 1;
      grid-column: 1 / 10;
      grid-row: 1;
    }

    > :nth-child(2) {
      margin-left: -30px;
      grid-column: 10 / 13;
      grid-row: 1;
    }

    > :nth-child(3) {
      grid-row: 2;
      grid-column: 1 / 13;
    }
    > :nth-child(4) {
      grid-row: 3;
      grid-column: 1 / 13;
    }
  }
`

export const LinkContainer = styled.div`
  background: ${props => props.theme.primaryColorLighter};
  border-top-right-radius: 30px;
  padding: 31px 20px 16px 20px;
  color: ${colors.veryPaleGreen};

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;

  .title {
    font-weight: 500;
    margin-top: 2px;
    margin-bottom: 0;
    @media (${device.tablet}) {
      margin-bottom: 27px;
    }
  }

  > div > :not(.title) {
    display: inline;
    :not(:last-child):after {
      content: ", ";
    }

    @media (${device.tablet}) {
      display: block;
      :after {
        display: none;
      }
    }
  }

  @media (${device.tablet}) {
    padding: 50px 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    > div {
      grid-row: 1;
      grid-column: 2;
    }
  }
`
