const getPageUrl = (page, config = {}) => {
  if (
    (!page || !page.path) &&
    page.__typename !== "DatoCmsEntrepreneurialStoriesTopic"
  ) {
    return "/"
  }

  switch (page.__typename) {
    case "DatoCmsJobVacancy":
      return `/${config.jobVacanciesParentPath}/${page.path}`
    case "DatoCmsEntrepreneurialStoriesTopic":
      return `/${config.storiesParentPath}/?topic=${page.path}`
    case "DatoCmsEntrepreneurialStory":
      return `/${config.storiesParentPath}/${page.path}`
    case "DatoCmsBlogPost":
      return `/${config.blogPostsParentPath}/${page.path}`
    case "DatoCmsTeamMember":
      return `/${config.teamMembersParentPath}/${page.path}`
    default:
      const ancestor = page.treeParent && page.treeParent.path
      if (ancestor) {
        return `/${ancestor}/${page.path}/`.replace(/\/\//g, "/")
      }

      return `/${page.path || ""}/`.replace(/\/\//g, "/")
  }
}

module.exports = {
  getPageUrl,
}
