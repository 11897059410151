import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  html, body {
    font-family: ${props => props.theme.font};
    color: ${props => props.theme.textColor};
  }
  
  b, strong {
    font-weight: 500;
  }
  
  a {
    color: currentColor;
    text-decoration: none;
    
    :hover {
      text-decoration: underline;
    }
  }
  
  ul {
    white-space: normal;
    li {
      white-space: pre-wrap;
    }
  }
`
