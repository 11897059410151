import styled, { css } from "styled-components"
import device from "../Theme/devices"

export const PromotionContainer = styled.div<{ visible: boolean }>`
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 200;
  border-radius: 10px;
  overflow: hidden;
  background: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
  width: calc(100vw - 90px);
  transition: opacity 0.2s ease, transform 0.2s ease;
  @media (${device.tablet}) {
    width: 32.9166vw;
    min-width: 474px;
  }
  ${props =>
    !props.visible &&
    css`
      pointer-events: none;
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.9);
    `}
`

export const PromotionText = styled.div`
  padding: 28px 28px 42px 28px;
  > *:first-child {
    margin-top: 0;
  }
  h1 {
    font-size: 26px;
  }
`

export const PromotionClose = styled.button`
  position: absolute;
  border: 0;
  outline: 0;
  top: 12px;
  right: 10px;
  width: 34px;
  z-index: 300;
  height: 34px;
  padding: 10px;
  background: transparent;
  svg {
    position: relative;
    z-index: 2;
  }
  &:before {
    display: block;
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ede8d0;
    border-radius: 50%;
    transition: transform 0.2s cubic-bezier(0.26, 1.12, 0.34, 1.83);
  }
  &:hover {
    cursor: pointer;
    &:before {
      transform: scale(1.1);
    }
  }
`

export const Logos = styled.div`
  svg:nth-child(2) {
    path {
      fill: white;
    }
  }
`

export const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
`
