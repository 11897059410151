import { DefaultTheme } from "styled-components"
import { colors } from "./colors"

export const AppTheme: DefaultTheme = {
  font: "Beatrice TRIAL, sans-serif",
  primaryColorDarker: colors.darkerBlue,
  primaryColor: colors.blue,
  primaryColorLighter: colors.lighterBlue,
  secondaryColor: colors.red,
  textColor: colors.almostBlackBlue,
  textColorDarker: "#000",
  textColorLighter: colors.gray,
  textColorLightest: colors.lighterGray,
}
