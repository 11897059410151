import CookieConsent from "react-cookie-consent"
import React from "react"

export const CookieBanner: React.FC = () => {
  return (
    <CookieConsent
      enableDeclineButton
      declineButtonText={"Liever niet"}
      buttonText={"Accepteren en doorgaan"}
      style={{ fontSize: "14px" }}
      cookieName={"ga-disable-UA-27886446-1"}
      cookieValue={"false"}
      declineCookieValue={"true"}
      buttonStyle={{
        borderRadius: 20,
        background: "#0530ce",
        padding: "13px 26px 12px 26px",
        color: "white",
      }}
      declineButtonStyle={{
        color: "#aaa9a5",
        background: "transparent",
        margin: "8px",
      }}
      onDecline={() => {
        //@ts-ignore
        window.gaOptout?.()
        window["ga-disable-UA-27886446-1"] = true
        window["ga-disable-GTM-PKJ7QWW"] = true
      }}
    >
      Wij maken op onze website gebruik van cookies voor het bijhouden van
      statistieken en om de gebruikerservaring te verbeteren.
    </CookieConsent>
  )
}
