import styled, { css } from "styled-components"
import { colors } from "../Theme/colors"
import { animated } from "react-spring"
import device from "../Theme/devices"

const LETTERS_WIDTH_DESKTOP = 444
const LETTERS_HEIGHT_DESKTOP = 56

export const Container = styled(animated.div)<{ visible: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${colors.blue};
  z-index: 1000;
  ${props =>
    !props.visible &&
    css`
      display: none;
    `}
`

export const LogoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 250ms ease-out;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${device.tablet}) {
    flex-direction: row;
  }

  > svg {
    width: 85px;
    height: 85px;
    margin: 0 20px;
  }
`

export const LettersContainer = styled(animated.div)`
  overflow: hidden;
  margin-top: 20px;
  svg {
    @media (${device.tablet}) {
      margin: 0;
      width: ${LETTERS_WIDTH_DESKTOP}px;
      height: ${LETTERS_HEIGHT_DESKTOP}px;
    }
    path {
      fill: white;
    }
  }
`

export const Logos = styled.div`
  svg:nth-child(2) {
    path {
      fill: white;
    }
  }
`
