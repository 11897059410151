import React, { useContext } from "react"

export const LoadingContext = React.createContext<{ loading: boolean }>({
  loading: false,
})

export const useAfterLoading = () => {
  const { loading } = useContext(LoadingContext)

  return loading
}
