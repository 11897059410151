import styled, { css } from "styled-components"
import device from "../Theme/devices"

export const PageTitle = styled.h1<{ huge?: boolean }>`
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 0;
  white-space: pre-wrap;

  @media (${device.desktop}) {
    ${props =>
      props.huge
        ? css`
            font-size: 70px;
            font-style: normal;
            font-weight: 600;
            line-height: 80px;
            letter-spacing: 0;
            text-align: left;
          `
        : css`
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 52px;
            letter-spacing: 0;
            text-align: left;
          `}
  }
`

export const SectionTitle = styled.h2<{
  topLevel?: boolean
  centered?: boolean
}>`
  font-size: 26px;
  font-style: normal;
  /*
    font-weight: ${({ topLevel }) => (topLevel ? 500 : 400)};
    Seems like this title weight is always 500 now
  */
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0;
  text-align: ${props => (props.centered ? "center" : null)};
`

export const Body2 = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
`

export const Body2Title = styled(Body2)`
  font-weight: 500;
`

export const Small = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
`

export const Huge = styled.div`
  font-size: 70px;
  line-height: 104px;
  @media (${device.tablet}) {
    font-size: 90px;
    line-height: 134px;
  }

  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  overflow: hidden;
  white-space: pre;
`

export const Body16 = styled.div<{ centered?: boolean }>`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-align: ${props => props.centered && "center"};
`

export const FormLabel = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  color: #807f7a;
`
