import React, { useCallback, useState } from "react"
import * as S from "./Sitemap.styled"
import { DatoCmsAction, DatoCmsActionProps } from "../DatoCmsAction"
import { Address } from "../../types/Address"
import { DatoCmsHtmlText } from "../DatoCmsHtmlText"
import { Body16 } from "../Texts"
import { useMedia } from "use-media"
import { size } from "../Theme/devices"

export const Sitemap: React.FC<{
  address: Address
  links: {
    id: string
    titleAction: DatoCmsActionProps
    links: DatoCmsActionProps[]
  }[]
}> = ({ links, address }) => {
  const [openIndex, setOpenIndex] = useState<number>(-1)
  const isDesktop = useMedia({ minWidth: size.desktop })
  const toggle = useCallback(
    (i: number) => () => setOpenIndex(c => (c === i ? -1 : i)),
    []
  )
  return (
    <S.Container>
      <S.Item>
        <DatoCmsHtmlText html={address.address} as={Body16} />
        <a href={`tel:${address.phone}`}>{address.phone}</a> <br />
        <a href={`mailto:${address.email}`}>{address.email}</a> <br />
      </S.Item>
      {links.map((l, i) => (
        <LinksContainer
          toggle={toggle(i)}
          isOpen={openIndex === i}
          key={l.id}
          isDesktop={isDesktop}
          link={l}
        />
      ))}
    </S.Container>
  )
}

const LinksContainer: React.FC<{
  link: { titleAction: DatoCmsActionProps; links: DatoCmsActionProps[] }
  isDesktop: boolean
  isOpen: boolean
  toggle: () => void
}> = ({ link, isDesktop, toggle, isOpen }) => {
  return (
    <S.Item>
      <S.Title onClick={!isDesktop ? toggle : undefined}>
        {isDesktop ? (
          <DatoCmsAction plain {...link.titleAction} />
        ) : (
          link.titleAction.label
        )}
      </S.Title>
      <S.LinksEmptyLine />
      <S.Links open={isOpen}>
        {!isDesktop &&
          link.titleAction.label !== link.links[0].label &&
          link.titleAction.link[0].__typename !== "DatoCmsEmptyAction" && (
            <DatoCmsAction plain {...link.titleAction} />
          )}
        {link.links.map(li => (
          <DatoCmsAction key={li.id} plain {...li} />
        ))}
      </S.Links>
    </S.Item>
  )
}
